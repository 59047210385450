<template>
  <div>
    <el-drawer :title="title" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="form" :model="checkForm" label-width="130px" size="medium">

        <el-form-item label="评语：">
          <div>
            {{row.content}}
          </div>
        </el-form-item>

        <el-form-item label="评价视频："   v-if="row.videoUrl">
          <sb-read-video :video-url="row.videoUrl" width="100px" height="50px"></sb-read-video>
        </el-form-item>

        <el-form-item label="评价图片："  v-if="row.picture">
          <div v-if="row.picture " style="display: flex;flex-wrap: wrap">
            <sb-image
                :src="item"
                style="margin: 0 4px 4px 0"
                v-for="(item,index) in row.picture.split(',')"
                :key="index"
                width="50px"
                height="50px"
                :src-list="[item]">
            </sb-image>
          </div>
        </el-form-item>
<!--        -->
        <div>
          <el-form-item label="审核图片：" v-if="row.voucherPicture">
            <div  style="display: flex;flex-wrap: wrap">
              <sb-image
                  :src="item"
                  style="margin: 0 4px 4px 0"
                  v-for="(item,index) in row.voucherPicture.split(',')"
                  :key="index"
                  width="50px"
                  height="50px"
                  :src-list="[item]">
              </sb-image>
            </div>
          </el-form-item>
			<div v-if="row.status == 2">
				<el-form-item label="审核结果：" prop="status">
					<el-radio-group v-model="checkForm.status" @change="handleChange">
						<el-radio :label="3">通过</el-radio>
						<el-radio :label="4">不合格</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item 
					label="原因备注：" 
					prop="reason" 
					:rules="[ {required:checkForm.status ==4,message:'请输入不合格原因',trigger:['change']} ]" >
					<el-input 
						v-model="checkForm.reason" 
						placeholder="请输入审核原因,选择不合格，需要填写原因" 
						class="inputWidth" 
						type="textarea"
						:rows="2" 
						clearable>
					</el-input>
				</el-form-item>
			</div>
         
        </div>


      </el-form>

      <div class="space"> </div>
      <div class="demo-drawer__footer" v-if="row.status == 2">>
        <el-button @click="dialogVisible = false"  size="medium">取 消</el-button>
        <el-button type="primary" @click="submit"  size="medium">确 定</el-button>
      </div>

    </el-drawer>
  </div>
</template>

<script>
import SbImage from '../../../components/image/image'
import SbReadVideo from '../../../components/readVideo/readVideo'
import {AUDITAPPRAISE} from "../../../api/base";

export default {
  name: "addAppraise",
  data(){
    return {
      dialogVisible:false,
      title:'',
      row:{},
      checkForm:{
        status:3,
        reason:'',
      },
    }
  },
  components:{
    SbImage,
    SbReadVideo
  },
  methods:{
    handleChange(){

    },
    submit(){
      if(this.title.includes('买家旺旺')){
        this.$refs.form.validate((valitor=>{
          if(valitor){
            this.$axios.post(AUDITAPPRAISE,this.checkForm).then(resp=>{
              if(resp.code == 200){
                this.dialogVisible = false;
                this.$mes({message:resp.msg})
                this.$emit('success')
              }
            })
          }
        }))
      }
    },
    handleClose(){
      if(this.row.status ==2){
        this.$confirm('确定提交表单了吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submit()
        })
      }else{
        this.$refs.form?.resetFields()
        setTimeout(()=>{
          this.dialogVisible = false;
        },50)
      }

    },
    open(row={}){
      this.row = row;
      this.dialogVisible = true;
      if(row.accountName){
        this.title ='买家旺旺：'+row.accountName;
        this.checkForm.id = row.id;
      }
      this.$refs.form?.resetFields()
    }
  }
}
</script>

<style scoped lang="less">
  .space{
    height: 74px;
  }
  .demo-drawer__footer{
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 60px;
    padding-bottom: 10px;
    .el-button{
      width: 40% !important;
    }

  }
</style>