<template>
  <div>
    <el-image
        :style="{width,height}"
        @click="dialogVisible = true"
        style="cursor: pointer;"
        :src="url">
    </el-image>
    <el-dialog
        z-index="999"
        :destroy-on-close="true"
        title="查看视频"
        :visible.sync="dialogVisible"
        width="700px"
        :before-close="handleClose"
        :close-on-click-modal="false">
      <video :src="copyUrl" width="100%" controls>
        您的浏览器不支持 video 标签。
      </video>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: "readVideo",
  data(){
    return {
      dialogVisible:false,
      url:'',
      copyUrl:'',

    }
  },
  computed:{
  },
  watch:{
    videoUrl:{
      handler(videoUrl){
        this.url = videoUrl+'?vframe/jpg/offset/0'
        this.copyUrl = videoUrl;
      },
      immediate:true,
    }
  },
  methods:{
    handleClose(){
      this.dialogVisible = false;
    }
  },
  props:{
    width:{
      type:[String]
    },
    height:{
      type:[String]
    },
    videoUrl:{
      type:[String]
    }
  }
}
</script>

<style scoped>

</style>