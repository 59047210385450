<template>
  <div style=" background: white;
        padding: 20px;border-right: 6px;">
    <div>
      <search-com @onSubmit="search" @reset="search"></search-com>
    </div>
    <el-table
        :data="appraiseList"
        style="width: 100%">

      <el-table-column
          label="序号"
          type="index"
      >
      </el-table-column>

      <el-table-column
          prop="platformName"
          label="平台">
      </el-table-column>

      <el-table-column
          prop="shopName"
          label="店铺名称">
      </el-table-column>

      <el-table-column
          prop="accountName"
          label="买家旺旺">
      </el-table-column>

      <el-table-column
          prop="orderNo"
          label="订单编号">
      </el-table-column>

<!--      <el-table-column-->
<!--          prop="content"-->
<!--          label="评语">-->
<!--        <template slot-scope="scope">-->
<!--&lt;!&ndash;          <el-tooltip class="item" effect="dark" :content="scope.row.content" placement="top">&ndash;&gt;-->
<!--            <div style="overflow:hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%">-->
<!--              {{ scope.row.content }}-->
<!--            </div>-->
<!--&lt;!&ndash;          </el-tooltip>&ndash;&gt;-->

<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="picture"-->
<!--          label="图片">-->
<!--        <template slot-scope="scope">-->
<!--          <div v-if="scope.row.picture " style="display: flex;flex-wrap: wrap">-->
<!--            <sb-image-->
<!--                :src="item"-->
<!--                style="margin: 0 4px 4px 0"-->
<!--                v-for="(item,index) in scope.row.picture.split(',')"-->
<!--                :key="index"-->
<!--                width="50px"-->
<!--                height="50px"-->
<!--                :src-list="[item]">-->

<!--            </sb-image>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column
          prop="price"
          width="80"
          label="佣金">
      </el-table-column>

<!--      <el-table-column-->
<!--          prop="videoUrl"-->
<!--          width="90"-->
<!--          label="视频">-->
<!--        <template slot-scope="scope">-->
<!--          <div v-if="scope.row.videoUrl">-->
<!--            <sb-read-video :videoUrl="scope.row.videoUrl" width="50px" height="50px">-->
<!--            </sb-read-video>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column
          width="60"
          label="状态">
        <template slot-scope="scope" >
          <el-tag :color="scope.row.statusStyle" size="mini" style="color: #fff">
            {{scope.row.statusLabel}}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="createTime"
          width="105"
          label="创建时间">
      </el-table-column>

      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-link type="primary" @click="check(scope.row)">{{ scope.row.status==2?'审核':'查看' }}</el-link>
        </template>
      </el-table-column>

    </el-table>
    <div style="height: 65px">
      <el-pagination
          style="float: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[50, 200, 800, 1200]"
          layout="total, pager,  sizes"
          :total="total">
      </el-pagination>
      <check-appraise ref="checkAppraise">
      </check-appraise>
    </div>
  </div>
</template>

<script>
import {APPRAISE} from "../../api/base";
import SearchCom from './components/searForm';
import CheckAppraise from './components/checkAppraise'
// import SbImage from '../../components/image/image'
// import SbReadVideo from '../../components/readVideo/readVideo'
export default {
  name: "evaluate",
  data(){
    return {
      appraiseList:[],
      total:0,
      listForm:{
        pageIndex:1,
        pageSize:50,
        // beginDate:'',
        // endDate:'',
      }
    }
  },
  components:{
    SearchCom,
    CheckAppraise,
  },
  mounted() {
    // this.listForm.beginDate = this.formmateTime(new Date())
    // this.listForm.endDate= this.formmateTime(new Date())
    this.getList()
  },
  methods:{
    check(row){
      this.$refs.checkAppraise.open(row)
    },
    handleSizeChange(val){
      this.listForm.pageSize = val;
      this.getList()
    },
    handleCurrentChange(val){
      this.listForm.pageIndex = val;
      this.getList()
    },
    search(form){
      this.listForm.pageIndex = 1;
      Object.assign(this.listForm,form)
      this.getList()
    },
    getList(){
      this.$axios.get(APPRAISE,this.listForm).then(resp=>{
        if(resp.code == 200){
          this.appraiseList = resp.data.data;
          this.total = resp.data.totalRow;
        }
      })
    }
  },
}
</script>

<style scoped>

</style>